import { Box } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { VM } from '../../../core/VM';
import GSPFSS from '../../../assets/images/logos/pofsis_no_border.png'

let view = VM()
export const Top = () => {
    const Loc = useLocation().pathname.split('/')
    const history = useHistory()
    

    const redirect = async (name) => {
        if (name === "main") {
            history.push('/')
        } else {
            let grabcurr = Loc
            grabcurr[1] = name
            history.push(`${grabcurr.join('/')}`)
        }
    }

    return (
        <Box width="100%">
            {
                view === '/devices/mobile' ?
                    <Box width="100%">
                        <Box display="flex" justifyContent="center" fontSize="12px" gap={1.5}>
                            {
                                [
                                    {text: 'Personal', ref: 'psl', color1: '#292C2F', color2: '#B5BBC1'},
                                    {text: 'Business', ref: 'biz', color1: '#5B6167', color2: '#B5BBC1'},
                                    ].map((data, key) => 
                                            <Box key={key} fontWeight={400} borderBottom={Loc[1]===data.ref ? `2px solid ${data.color2}` : ''} borderRadius="0px" sx={{pt: '24px', pb: '12px', pl: '40px', pr: '40px', color: data.color1, ':hover': {bgcolor: 'transparent'}}} onClick={()=>redirect(data.ref)}>{data.text}</Box>
                                    ) 
                            }
                        </Box>
                    </Box>
                :
                    <Box width="100%" px="23px" py="19.5px" display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" justifyContent="center" alignItems="center" className='c-pointer'  minWidth="100px" onClick={()=>redirect('main')}>
                                <Box px="11px" py="4px">
                                    <img src={GSPFSS} alt={GSPFSS} width="26" />
                                </Box>
                                <Box fontSize={24} fontWeight="800" color="#292C2F" ml="10px" display="flex">POF<Box fontWeight="400" color="#292C2F" >SIS</Box></Box>
                        </Box>
                        <Box display="flex" justifyContent="center" fontSize="16px">
                            {
                                [
                                    {text: 'Personal', ref: 'psl', color1: '#292C2F', color2: '#B5BBC1'},
                                    {text: 'Business', ref: 'biz', color1: '#5B6167', color2: '#B5BBC1'},
                                    ].map((data, key) => 
                                            <Box key={key} fontWeight={Loc[1] === data.ref ? 700 : 400} borderBottom={Loc[1]===data.ref ? `2px solid ${data.color2}` : ''} borderRadius="0px" sx={{pt: '44px', pb: '22px', pl: '40px', pr: '40px', color: data.color1, ':hover': {bgcolor: 'transparent'}}} onClick={()=>redirect(data.ref)} className='c-pointer'>{data.text}</Box>
                                    ) 
                            }
                        </Box>
                        <Box minWidth="100px"></Box>
                    </Box>
            }
        </Box>
    );
}
