import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { domain, env } from './core/Env';
import { rqx } from './core/request/API';
import { SnakeLoader } from './core/loader/SnakeLoader';
import LLogo from './assets/images/logos/pofsis_no_border.png'
import LMP from './assets/images/logos/mp_logo.png'
import PCHTLOG from './assets/images/logos/pcht_logo.png'
import PHRCLOG from './assets/images/logos/hrc_logo.png'
import PFJOBLOG from './assets/images/logos/pofjob_logo.png'
import PFSHPLOG from './assets/images/logos/pfshp_logo.png'
import POFREFERRALLOG from './assets/images/logos/rfrl_logo.png'
import ErrorBot from './assets/images/core/error_bot.jpg'

export const SSORqx = () => {
    return <Router><Validate /></Router>
}

const Validate = () => {
    const q = new URLSearchParams(useLocation().search);
    const [err, setErr] = useState({stat: false, msg: ''})

    const keyHandler = async(tsa) => {
        return await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/tools/ssorqx`, {tsa_key: tsa})
    }

    const strgHandler = (__tool, cb, tid, tinfo = [{name: 'Tool', logo: JSON.stringify(LMP)}]) => {
        if (__tool === 'MP') {
            let subdir = q.get('from') === 'MP_DIRECT_CHECKOUT_RQX' ? '/subscription/checkout/rqx' : '/sso/account/auth';
            cb = q.get('callback')==='local'?`http://localhost:3001${subdir}`:q.get('callback')==='development'?`https://dev-market.pofsis.com${subdir}`:q.get('callback')==='sb' || q.get('callback')==='sandbox'?`https://sb-market.pofsis.com${subdir}`:`https://market.pofsis.com${subdir}`
        } else if (__tool === 'PCHT') {
            tinfo = [{name: 'POF Chat', logo: JSON.stringify(PCHTLOG)}]
            let subdir = q.get('from') === 'PCHT_SSO_RQX' ? '/sso/account/auth' : '/sso/account/auth';
            cb = q.get('callback')==='local'?`http://localhost:3000${subdir}`:q.get('callback')==='development'?`https://dev-chat.pofsis.com${subdir}`:q.get('callback')==='sandbox'?`https://sb-chat.pofsis.com${subdir}`:`https://chat.pofsis.com${subdir}`
        } else if (__tool === 'PFSHP') {
            tinfo = [{name: 'POF Shop', logo: JSON.stringify(PFSHPLOG)}]
            let subdir = q.get('from') === 'PFSHP_SSO_RQX' ? '/sso/account/auth' : '/sso/account/auth';
            cb = q.get('callback')==='local'?`http://localhost:3001${subdir}`:q.get('callback')==='development'?`https://dev-shop.pofsis.com${subdir}`:q.get('callback')==='sandbox'?`https://sb-shop.pofsis.com${subdir}`:`https://shop.pofsis.com${subdir}`
        } else if (__tool === 'PHRC') {
            tinfo = [{name: 'HRC', logo: JSON.stringify(PHRCLOG)}]
            let subdir = q.get('from') === 'PHRC_SSO_RQX' ? '/sso/account/auth' : '/sso/account/auth';
            cb = q.get('callback')==='local'?`http://localhost:3001${subdir}`:q.get('callback')==='development'?`https://dev-hrc.pofsis.com${subdir}`:q.get('callback')==='sandbox'?`https://sb-hrc.pofsis.com${subdir}`:`https://hrc.pofsis.com${subdir}`
        } else if (__tool === 'PFJOB') {
            tinfo = [{name: 'POF Job', logo: JSON.stringify(PFJOBLOG)}]
            let subdir = q.get('from') === 'PFJOB_SSO_RQX' ? '/sso/account/auth' : '/sso/account/auth';
            cb = q.get('callback')==='local'?`http://localhost:3001${subdir}`:q.get('callback')==='development'?`https://dev-job.pofsis.com/psl${subdir}`:q.get('callback')==='sandbox'?`https://sb-job.pofsis.com/psl${subdir}`:`https://job.pofsis.com/psl${subdir}`
        } else if (__tool === 'PFREFERRAL') {
            tinfo = [{name: 'POF Referral', logo: JSON.stringify(POFREFERRALLOG)}]
            let subdir = q.get('from') === 'PFREFERRAL_SSO_RQX' ? '/sso/account/auth' : '/sso/account/auth';
            cb = q.get('callback')==='local'?`http://localhost:3001${subdir}`:q.get('callback')==='development'?`https://dev-referral.pofsis.com/psl${subdir}`:q.get('callback')==='sandbox'?`https://sb-referral.pofsis.com/psl${subdir}`:`https://referral.pofsis.com/psl${subdir}`
        }

        let strg = {cb, tid, tinfo, expiration: '', platform: q.get('platform'), tsa_key: q.get('from'), pricing: q.get('pricing'), subcb: q.get('subcb'), ref_a_frnd: q.get('ref_a_frnd')}
        localStorage.setItem('pm_tool_session', JSON.stringify(strg))

        return strg
    }

    useEffect(() => {
        const __init = async () => {
            let entrypoint = q.get('entry') === 'ubr_main' || q.get('entry') === 'ubr_profile' || q.get('entry') === 'ubr_subtool' ? `/ubr/${q.get('entry')}` : '' 
            if (q.get('from') === 'MP_SSO_RQX' || q.get('from') === 'MP_MARKET_CHECKOUT_SSO_RQX') {
                const strg = strgHandler('MP', q.get('callback'), q.get('tid'))
                window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/login?from=${encodeURIComponent(JSON.stringify(strg))}`
            } else if (q.get('from') === 'MP_DIRECT_CHECKOUT_RQX') {
                const strg = strgHandler('MP', q.get('callback'), q.get('tid'))
                let url = ''
                if (q.get('account')==='none') {
                    url = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/${q.get('auth_type')}?from=${encodeURIComponent(JSON.stringify(strg))}`
                } else {
                    let platform = q.get('platform') === 'personal' ? 'psl' : 'biz'
                    let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/email`, {account: q.get('account'), ref: 1, platform: platform, tool_auth: q.get('tid')})
                    if (attempt.msg === 'valid') {
                        let { ainfo } = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/tparty`, {account: q.get('account'), ref: 'tparty', platform: platform})
                        let cb = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/tools/toolcb`, {tid: q.get('tid'), env: env()==='prod'?'production':'sandbox'})
                        url = `${cb.tu[0].value}?ainfo=${encodeURIComponent(JSON.stringify(ainfo))}&subcb=${q.get('subcb')}`
                    } else if (attempt.msg === '!account') {
                        let { ainfo } = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/tparty`, {account: q.get('account'), ref: 'tparty', platform: platform})
                        let subdir = `ainfo=${encodeURIComponent(JSON.stringify(ainfo))}`
                        subdir += `&platform=${q.get('platform')}&tid=${q.get('tid')}&pricing=${encodeURIComponent(q.get('pricing'))}&ref=auth&subcb=${q.get('subcb')}`
                        url = `${domain('mp').url}/subscription/checkout/rqx?${subdir}`
                    } else {
                        url = `/${q.get('platform')==='personal'?'psl':'biz'}/registration/finalize?from=${encodeURIComponent(JSON.stringify(strg))}&sainfo=${encodeURIComponent(JSON.stringify({va: 'email', account: window.btoa(`unauth_${q.get('account')}`)}))}`
                    }
                }
                window.location.href = url
                
            } else if (q.get('from') === 'TOOL_MARKET_PREVIEW_SSO_RQX') {
                let key = await keyHandler(q.get('tsa_key'))
                if (key.msg !== '!tk') {
                    const strg = strgHandler('MP', q.get('callback'), key.tinfo[0].tid)
                    window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}/registration?from=${encodeURIComponent(JSON.stringify(strg))}`
                } else {
                    setErr({stat: true, msg: 'AUTHORIZATION_ERROR: TSA key does not exist.'})
                }
            } else if (q.get('from') === 'PCHT_SSO_RQX') {
                const strg = strgHandler('PCHT', q.get('callback'), q.get('tid'))
                window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/${q.get('auth_type')==='registration' ? 'registration' : 'login'}?from=${encodeURIComponent(JSON.stringify(strg))}`
            } else if (q.get('from') === 'PFSHP_SSO_RQX' || q.get('from') === 'PFSHP_SSO_DIRECT_RQX') {
                const strg = strgHandler('PFSHP', q.get('callback'), q.get('tid'))
                window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/${q.get('auth_type')==='registration' ? 'registration' : 'login'}?from=${encodeURIComponent(JSON.stringify(strg))}`
            } else if (q.get('from') === 'PHRC_SSO_RQX' || q.get('from') === 'PHRC_SSO_DIRECT_RQX') {
                const strg = strgHandler('PHRC', q.get('callback'), q.get('tid'))
                window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/${q.get('auth_type')==='registration' ? 'registration' : 'login'}?from=${encodeURIComponent(JSON.stringify(strg))}`
            } else if (q.get('from') === 'PFJOB_SSO_RQX' || q.get('from') === 'PFJOB_SSO_DIRECT_RQX') {
                const strg = strgHandler('PFJOB', q.get('callback'), q.get('tid'))
                window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/${q.get('auth_type')==='registration' ? 'registration' : 'login'}?from=${encodeURIComponent(JSON.stringify(strg))}`
            } else if (q.get('from') === 'PFREFERRAL_SSO_RQX' || q.get('from') === 'PFREFERRAL_SSO_RQX_SSO_DIRECT_RQX') {
                const strg = strgHandler('PFREFERRAL', q.get('callback'), q.get('tid'))
                window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/${q.get('auth_type')==='registration' ? 'registration' : 'login'}?from=${encodeURIComponent(JSON.stringify(strg))}`
            } else {
                let key = await keyHandler(q.get('from'))
                if (key.msg !== '!tk') {
                    if (key.tu.length > 0) {
                        const cb = key.tu[0][`${q.get('callback')}_auth_callback`].value
                        if (cb !== '') {
                            const strg = strgHandler('tool', cb, key.tinfo[0].tid, key.tinfo)
                            window.location.href = `/${q.get('platform')==='personal'?'psl':'biz'}${entrypoint}/tool/login?from=${encodeURIComponent(JSON.stringify(strg))}&tool_auth=${key.tinfo[0].name}`
                        } else {
                            setErr({stat: true, msg: 'AUTHORIZATION_ERROR: Callback URL is missing.'})
                        }   
                    } else {
                        setErr({stat: true, msg: 'AUTHORIZATION_ERROR: Callback URL is missing.'})
                    }
                } else {
                    setErr({stat: true, msg: 'AUTHORIZATION_ERROR: TSA key does not exist.'})
                }
            }
        }

        __init()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return !err.stat ? (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img src={LLogo} width={50} alt={LLogo} />
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    ) : (
        <Box height="100%" width="100%" display="flex" justifyContent="center" bgcolor="#ffffff">
            <Box height="100%" width="400px" display="flex" justifyContent="center" alignItems="center">
                <Grid container>
                    <Grid item xs={12}>
                        <img src={ErrorBot} alt={ErrorBot} width="100%" />
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Box color="primary.main" fontSize={14} >POF<strong>SIS</strong></Box>
                            <Box fontSize={24}><b>SHEEESSHH!</b></Box> 
                            {/* <Box fontSize={18} mb={3}>Looks like an expolosion...</Box> */}
                            <Box fontSize={18} mb={3}>Well, this is unexpected. . .</Box>
                            <Box textAlign="justify" fontSize={14} mb={1}>
                                We were unable to authenticate you because your tool did not provide the proper authorization. Please contact your tool's customer support for assistance with this issue.
                            </Box>
                            <Box textAlign="justify" fontSize={14} color="gray">
                                Error code: {err.msg}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}